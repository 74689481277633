<template>
  <div class="property-amenities-slider">
    <div class="property-amenities-slider__header">
      <h3 class="property-amenities-slider__title mr-2">Informazioni importanti</h3>
      <p class="property-amenities-slider__subtitle">Consulta le seguenti categorie di informazioni</p>
    </div>

    <div class="property-amenities-slider__slick">
      <span class="property-amenities-slider__arrow" id="slick-prev-review"><SvgChevronLeft /></span>
      <Slick :options="slickOptions">
        <div
          :class="'property-amenities-slider__icon amenity '+(icons['amenities'][at.id] !== undefined ? icons['amenities'][at.id] : 'icon-default')"
          v-for="(at,index) in Object.values(amenities)"
          v-bind:key="index"
          @click="handlerModalAmenities"
        >
          <span v-html="at.name" />
        </div>
      </Slick>
      <span class="property-amenities-slider__arrow" id="slick-next-review"><SvgChevronRight /></span>
    </div>
  </div>
</template>

<script>
import Slick from 'vue-slick'
import SvgChevronLeft from "../../svg/chevron-left.vue";
import SvgChevronRight from "../../svg/chevron-right.vue";
import Vue from "vue";

export default {
  name: 'property-amenities-slider',
  components:{
    SvgChevronRight, SvgChevronLeft,
    Slick,
  },
  data(){
    return {
      icons: Vue.prototype.$config.icons,
      slickOptions: {
        prevArrow: '#slick-prev-review',
        nextArrow: '#slick-next-review',
        variableWidth: true,
      },
    }
  },
  props: {
    amenities: Object
  },
  computed: {
  },
  methods: {
    handlerModalAmenities(event) {
      this.$emit('get_modal_amenities', event);
    },
  }
}
</script>

<style lang="scss">

  .property-amenities-slider {
    margin-top: $grid-gutter-width/2;

    &__header {
      padding-top:0 !important;
      padding-bottom:0 !important;
      @include customize-bordered-element("secondary");
    }

    &__title {
      color:$black;
      font-size: 23px;
      margin-bottom: 0;
    }

    &__subtitle {
      font-size: 13px;
      margin-bottom: 0;
    }

    &__slick {
      position: relative;
    }

    &__icon {
      max-width: initial;
      min-width: initial;
      padding-right: 0;
      margin-bottom: 0;
      padding: $grid-gutter-width/4;

      &:before {
        margin-right: 5px;
        margin-bottom: -3px;
      }
    }

    &__arrow {
      border-radius: 100%;
      background-color: $white;
      padding: 10px;
      cursor: pointer;
      position: absolute;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 1;

      &:first-child {
        left: 0;
      }
      &:last-child {
        right: 0;
      }
    }

    .slick {

      &-list {
        padding: $grid-gutter-width/2 0;
        margin: 0 $grid-gutter-width;
      }

      &-slide {
        background-color: $white;
        margin-right: 20px;
        border-radius: $grid-gutter-width/2;
        box-shadow: $box-shadow;
      }
    }
  }

</style>