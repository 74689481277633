<template>
    <div class="property-details-modal">

      <div v-for="(field, index) in Object.keys(titoli)" v-bind:key="'property-details-modal-field-' + index">

        <div v-if="details[field]">
          <h6 class="property-detail-title">
            {{ titoli[field] }}
          </h6>
          <div class="property-detail-content">
            <p v-html="details[field]"></p>
          </div>
          <br />
        </div>

      </div>

      <div v-if="details.parkingInfos">
        <h6 class="property-detail-title">Parcheggio</h6>
        <div class="property-detail-content" v-html="details.parkingInfos" />
        <br />
      </div>

      <div v-if="details.attributes.pets">
        <h6 class="property-detail-title">Policy animali</h6>
        <ul v-for="(value,index) in details.attributes.pets" v-bind:key="'property-info-'+index">
          <li v-html="value.name" class="property-detail-content"></li>
        </ul>
        <br />
      </div>

    </div>
</template>

<script>
export default {
    name: 'property-toKnow',
    props:
    {
        details: Object,
    },
    data(){
      return {
        titoli: {
          //specialDetails: "INFORMAZIONI IMPORTANTI", // sono già indicate fuori da questa modal (ed è un'array)

          fees_mandatory: "Costi obbligatori",
          fees_optional: "Costi opzionali",
          renovations: "Lavori di ristrutturazione",
          specialCheckInInstructions: "Istruzioni speciali per il check in",
          checkInInstructions: "Istruzioni per il check in",
          knowBeforeYouGo: "Da sapere prima di partire",

          //amenities: "Servizi",
          //pets: "Policy animali",
          //nations_ratings: "Rating",
          //business_amenities: "Servizi business",
          //rooms: "Descrizione delle camere",
          //attractions: "Attrazioni nelle vicinanze",
          //location: "Location",

        },
      }
    },
}
</script>

<style lang="scss">
    
    // see app.scss
    .property-details-modal
    {
        text-align:left;
        /*
        p
        {
            font-size: 15px;
            font-weight:300;
            color:$black;
        }
        */
        &:first-child .property-detail-title
        {
            margin-top:0;
        }
        .property-detail-title
        {
            margin-top:1rem;
            //font-size: $font-size-sm;
            color:$black;
        }
        .property-detail-content
        {
            //font-size: $font-size-sm;
        }
        ul
        {
            margin-bottom:0.75rem;
        }
        li
        {
            padding-left:1rem;
            position:relative;
            display:block;
            width:100%;
            margin-bottom: 15px;

            &:before
            {
                content:"";
                position:absolute;
                left:0;
                top:50%;
                transform:translateY(-50%);
                height:5px;
                width:5px;
                border-radius:50%;
                background:$black;
            }
        }
    }
</style>