<template>
    <div class="stars">
        <span class="StarOk" v-for="star in Math.floor(hotelRating)" v-bind:key="'starOk-'+star">
            <StarOk />
        </span>
        <span  class="StarHalf" v-for="star in ((!Number.isInteger(hotelRating)) ? 1 : 0)" v-bind:key="'starHalf-'+star">
            <StarHalf />
        </span>
        <!--
        <span class="StarKo" v-for="star in (5 - Math.floor(hotelRating) - ((!Number.isInteger(hotelRating)) ? 1 : 0))" v-bind:key="'starKo-'+star">
            <StarKo />
        </span>
        -->
    </div>
</template>

<script>
import StarOk from '../svg/review-star-ok'
//import StarKo from '../svg/review-star-ko'
import StarHalf from '../svg/review-star-half'

export default {
    name: 'stars',
    props:{
        hotelRating: Number //3.5
    },
    components:{
        StarOk,
        //StarKo,
        StarHalf
    }
}
</script>

<style lang="scss">
    
    
    
    

    .stars
    {
        display:flex;
        align-items: center;
        justify-content: center;
        svg
        {
            max-width: 100%;
            path
            {
                @include customize-fill("primary");
            }
        }
        .StarKo
        {
            svg path
            {
                fill:$white;
            }
        }
        .StarHalf
        {
            svg path:nth-child(2)
            {
                fill:$white;
            }
        }
    }
</style>