<template>
    <div class="property-details">
        <div v-if="loadingProperty" class="hide-multiple-spinner">
            <Spinner />
        </div>

        <div v-else>
            <div class="property">
                <b-row >
                    <b-col sm="12">
                        <div @click="goBack()"><BtnBack :text="'Torna indietro'" /></div>
                    </b-col>
                </b-row>
                
                <b-row>
                    <b-col sm="12">
                        <div class="property-details__top">
                            <div>
                                <div class="d-flex align-items-center">
                                    <h3 class="property__heading mr-2">{{property.name}}</h3>
                                    <LiteFavorite :property="property.lite" />
                                    <!--<LiteVisited :property="property.lite" />-->
                                    <Stars :hotelRating="parseFloat(property.stars.intval+'.'+property.stars.floatval)" />
                                </div>
                                <PropertyRates
                                    :score_description="property.static.score_description"
                                    :salabamScore="property.static.salabamScore"
                                    :hotelRating="property.static.hotelRating"
                                />
                            </div>
                        </div>
                    </b-col>
                </b-row>

                <div class="property__highlight">

                    <div class="highlight__left">
                        <div class="slick-container">
                            <Slick
                                id="slickTop"
                                ref="slickTop"
                                :options="slickOptions"
                            >
                                <div v-for="(value,imageIndex) in (property.images).slice(0,100)" v-bind:key="imageIndex">
                                    <div class="property-image" @click="toggleInternalModal('slickTopFullwidth',true,imageIndex)">
                                        <div class="property-image-bg" :style="'background-image:url('+value.src+')'"></div>
                                        <span class="slick-caption">{{value.caption}}</span>
                                    </div>
                                </div>
                            </slick>
                            <span variant="light" class="slick-arrow" id="slick-prev"><SvgChevronLeft /></span>
                            <span variant="light" class="slick-arrow" id="slick-next"><SvgChevronRight /></span>
                       </div>

                        <b-modal id="slickTopFullwidth" data-class="internalGallery" ref="slickTopFullwidth" size="xl" hide-header hide-footer>
                            <div class="d-block text-center slick-container">
                                <Slick
                                    :options="{
                                        dots:true,
                                        fade:true,
                                        prevArrow: '#slick-prev-internal',
                                        nextArrow: '#slick-next-internal',
                                        asNavFor: '#slickTop',
                                        initialSlide: firstSlideIndex
                                    }"
                                >
                                    <div v-for="(value,imageModalIndex) in (property.images).slice(0,100)" v-bind:key="imageModalIndex" class="internalImage">
                                        <div class="slick-caption"><span>{{value.caption}}</span></div>
                                        <img :src="value.src">
                                    </div>
                                </slick>
                                <span variant="light" class="slick-arrow slick-prev" id="slick-prev-internal"><SvgChevronLeft /></span>
                                <span variant="light" class="slick-arrow slick-next" id="slick-next-internal"><SvgChevronRight /></span>
                            </div>
                            <div class="closeInternal" @click="toggleInternalModal('slickTopFullwidth',false,imageModalIndex)">
                                <SvgCross />
                            </div>
                        </b-modal>
                    </div>

                    <div class="highlight__right">

                        <div class="property__amenities">
                            <span v-for="(at,index) in (Object.values(property.details.propertyAmenities)).slice(0, 2)" v-bind:key="index" :class="'amenity '+(icons['amenities'][at.id] !== undefined ? icons['amenities'][at.id] : 'icon-default')">
                                {{at.name}}
                            </span>
                        </div>
                        <div
                            @click="$bvModal.show('modal-property-attributes')"
                            v-if="Object.values(property.details.propertyAmenities).length"
                        >
                            <BtnChevronRight :text="'Tutti i servizi'" />
                        </div>                  
                        <b-modal id="modal-property-attributes" size="lg" hide-footer>
                            <template v-slot:modal-title>
                                Potrebbero prevedere supplementi da pagare in loco
                            </template>
                            <div class="modal-section">
                                <div class="property__amenities">
                                    <span v-for="(at,index) in Object.values(property.details.propertyAmenities)" v-bind:key="index" :class="'amenity '+(icons['amenities'][at.id] !== undefined ? icons['amenities'][at.id] : 'icon-default')">
                                        {{at.name}}
                                    </span>
                                </div>
                            </div>
                        </b-modal>

                        <hr>
                        
                        <a @click="mixinGoTo('map-detail',{
                            slug: (property.name).replace(/ /g, '-'),
                            title: property.name,
                            propertyId: parseInt(propertyId),
                            property: propertyPad
                        })">
                            <span class="map-detail"><SvgMapSmall /></span>
                        </a>

                        <!-- obfuscated è diventato obfuscation_required in ra2.4 con questo trick non devo fare una nuova versione-->
                        <p @click="mixinGoTo('map-detail',{
                            slug: (property.name).replace(/ /g, '-'),
                            title: property.name,
                            propertyId: parseInt(propertyId),
                            property: propertyPad
                        })" class="pointer property__address" v-if="!property.address.obfuscated && !property.address.obfuscation_required">
                            <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.49988 0.286011C2.69988 0.286011 0.399902 2.58605 0.399902 5.38605C0.399902 8.18605 3.39988 11.386 5.49988 13.186C7.59988 11.386 10.5999 8.18605 10.5999 5.38605C10.5999 2.58605 8.29988 0.286011 5.49988 0.286011ZM5.49988 6.58606C4.39988 6.58606 3.59985 5.68604 3.59985 4.68604C3.59985 3.68604 4.49988 2.78601 5.49988 2.78601C6.49988 2.78601 7.3999 3.68604 7.3999 4.68604C7.3999 5.68604 6.49988 6.58606 5.49988 6.58606Z" fill="#0071BC" />
                            </svg>
                            {{property.address.line_1}} {{property.address.postal_code}} {{property.address.city}} {{property.address.state_province_name}} {{property.address.country_code}}
                        </p>

                        <hr>

                        <p class="property-pad__description">
                            <span v-if="(property.details.location).length<125">
                                {{ property.details.location }}
                            </span>
                            <span v-else>
                                {{ property.details.location.substring(0,125)+".." }}
                            </span>
                            <i @click="$bvModal.show('modal-property-details')"> dimmi di più</i>
                            <b-modal id="modal-property-details" size="lg" hide-footer>
                                <template v-slot:modal-title>
                                    Informazioni sulla struttura
                                </template>
                                <div class="modal-section">
                                    <div class="d-block text-center">
                                        <PropertyInfo :details="property.details" />
                                    </div>
                                </div>
                            </b-modal>
                        </p>

                    </div>

                </div>
            </div>
        </div>


        <!--<PropertyCovidAlert v-if="!loadingProperty && config.guiSettings.covidAlertTxt != '-'" :details="property.details" />-->
        <PropertyConditions />


        <div v-if="loadingRooms" class="hide-multiple-spinner">
            <Spinner />
        </div>

        <div v-else>

          <PropertyAmenitiesSlider :amenities="property.details.propertyAmenities" @get_modal_amenities="showModalAmenities" />

          <div class="rooms">
            <h5 class="rooms__title">Scegli la tua camera</h5>
            <div class="search desktop-only">
                <SearchForm
                    :elementSlug="'detail-search'"
                    :offset="0"
                    :submitCallback="submitCallback"
                    v-on:submit-search="updateSearch()"
                    v-on:filter-changed="this.$parent.filterChanged"
                    v-on:reset-results="this.$parent.resetResults"
                    v-on:reset-results-and-trigger="this.$parent.resetResultsAndTrigger"
                    v-on:search-form-is-valid="this.$parent.searchFormIsValid"
                    :ignoreMapRedirect="true"
                />
                <b-alert v-if="oldSearchMessage" variant="danger" class="my-3" show>
                  Per avviare una nuova ricerca devi modificare i parametri.
                </b-alert>
            </div>
            <div v-if="rooms.length > 0">
                <Room v-for="(room,i) in rooms" v-bind:key="i" :propertyDetails="property.details" :room="room" v-on:room-selected="roomSelected" />
            </div>
            <b-alert variant="warning" class="mb-4" v-else show>
                Non ci sono camere disponibli in questa struttura nelle date indicate.
            </b-alert>
            <!--
            <router-link :to="{ name: 'search'}">
                <BtnBack v-if="rooms.length > 0" :text="'Torna ai risultati'" />
                <BtnBack v-else :text="'Torna alla ricerca'" />
            </router-link>
            <br><br>
            --->
          </div>
        </div>
        
        <div v-if="loadingProperty" class="hide-multiple-spinner">
            <Spinner />
        </div>

        <div v-else>
            <div class="info">
                <div class="info__panel">
                    <h6 class="info__panel-title">
                        Informazioni importanti
                    </h6>
                    <div class="info__panel-content">
                        <PropertyImportantInfo v-if="property.details.specialDetails" :specialDetails="property.details.specialDetails" />

                      &nbsp;
                      <PropertyToKnow :details="property.details" />
                    </div>
                    <!--<span class="d-flex justify-content-center mt-4" v-b-toggle.collapse-amenities>
                        <BtnChevronRight :text="'Leggi tutto'" />
                    </span>
                    <b-collapse id="collapse-amenities" class="mt-2">
                      <PropertyToKnow :details="property.details" />
                    </b-collapse>-->
                </div>
            </div>

            <b-row>
                <b-col sm="12">
                    <router-link :to="{ name: 'search'}">
                        <BtnBack v-if="rooms.length > 0" :text="'Torna ai risultati'" />
                        <BtnBack v-else :text="'Torna alla ricerca'" />
                    </router-link>
                </b-col>
            </b-row>
        </div>
     
    </div>
</template>

<script>
    import Vue from 'vue'
    import Api from '@/api'
    import { mapGetters } from 'vuex'
    import User from '@/user'
    import SearchForm from '@/components/blocks/search-form/search-form'
    import SvgMapSmall from '@/components/svg/map-small'
    import Stars from '@/components/atoms/stars'
    import BtnChevronRight from '@/components/atoms/btn-chevron-right'
    import BtnBack from '@/components/atoms/btn-back'
    import Room from '@/components/blocks/room'
    import PropertyInfo from '@/components/blocks/property/property-info'
    import PropertyToKnow from '@/components/blocks/property/property-toKnow'
    //import PropertyCovidAlert from '@/components/blocks/property/property-covid-alert'
    import PropertyConditions from '@/components/blocks/property/property-conditions'
    import PropertyImportantInfo from '@/components/blocks/property/property-important-info'
    import PropertyRates from '@/components/blocks/property/property-rates'
    import PropertyAmenitiesSlider from '@/components/blocks/property/property-amenities-slider'
    import Spinner from '@/components/atoms/spinner'
    import Slick from 'vue-slick'
    import SvgChevronRight from '@/components/svg/chevron-right'
    import SvgChevronLeft from '@/components/svg/chevron-left'
    import SvgCross from '@/components/svg/cross'
    import LiteFavorite from '@/components/atoms/lite-favorite'
    //import LiteVisited from '@/components/atoms/lite-visited'

    export default {
        name: 'property',
        metaInfo () {
        return {
            title: (this.propertyName !== '') ? this.propertyName : 'live booking',
        }
        },
        components: {
            SearchForm,
            SvgMapSmall,
            BtnBack,
            BtnChevronRight,
            Stars,
            Room,
            PropertyInfo,
            PropertyToKnow,
            PropertyImportantInfo,
            //PropertyCovidAlert,
            PropertyConditions,
            PropertyRates,
            PropertyAmenitiesSlider,
            Spinner,
            Slick,
            SvgChevronLeft,
            SvgChevronRight,
            SvgCross,
            LiteFavorite,
            //LiteVisited,
        },
    //props: ["propertyId", "propertyPad", "propertyName"],
    props:
    {
        propertyId: String,
        propertyPad: Object,
        propertyName: {
            type: String,
            default: '',
        },
    },
    computed:{
      ...mapGetters({
         checkin:   'getCheckin',
         checkout:   'getCheckout',
         nights:  'getNights',
         occupancy: 'getOccupancy',
         affiliateReferenceId: 'getAffiliateReferenceId',
         geo: 'getGeo',
       }),
    },
    watch: {
        propertyId: function () {
            this.getPropertyDetails()
        }
    },
    data() {
        return {
            imageModalIndex: 0,
            firstSlideIndex: 0,
            config: Vue.prototype.$config,
            icons:              Vue.prototype.$config.icons,
            amenitiesLabels:    Vue.prototype.$config.amenities,
            loadingProperty: true,
            loadingRooms: true,
            property: {},
            rooms: {},
            initialSearch: {},
            slide: 0,
            slickOptions: {
                    dots:true,
                    fade:true,
                    prevArrow: '#slick-prev',
                    nextArrow: '#slick-next',
                },
            submitCallback: function()
            {
                // questo form non prevede nessun redirect
                // this.mixinGoTo('search',{})
            },

            detailsSearchParams: {},
            oldSearchMessage : false,
            site: this.appConfig().site,
        }
    },
    mounted() {
        this.getPropertyDetails()
    },
    methods: {

      goBack()
      {
        if(process.env.VUE_APP_MODE != 'production') window.console.log('history.back')
        history.back()
      },

      parseGa4EcommerceItems(data, brand) {
        const items = [];
        const propertyId = data.content.property_id;
        const propertyName = data.content.name;

        data.availabilities.rooms.forEach(room => {
          const roomId = room.id;
          const roomName = room.room_name;

          room.rates.forEach(rate => {
            const rateId = rate.id;
            const itemId = `${propertyId}-${roomId}-${rateId}`;
            const itemName = `${propertyName} - ${roomName}`;
            const price = rate.live_price;
            const quantity = rate.available_rooms;

            const item = {
              item_id: itemId,
              item_name: itemName,
              item_brand: brand, // Assuming _this.site.brand is passed as 'brand'
              price: price,
              item_category: "", // Assuming no category is provided
              quantity: quantity
            };

            items.push(item);
          });
        });

        return items;
    },
    getPropertyDetails()
      {
          let _this = this
              this.loadingProperty = true,
              this.loadingRooms = true,
              this.oldSearch = JSON.stringify({
                  checkin: _this.checkin,
                  checkout: _this.checkout,
                  occupancy: _this.occupancy,
                  propertyId: parseInt(_this.propertyId),
              })

      let parameters = {
          checkin: _this.checkin,
          checkout: _this.checkout,
          occupancy: _this.occupancy,
          propertyId: parseInt(_this.propertyId),
      }
      Api
          .getDetails(parameters)
          .then((data) => {
              _this.parseProperty(data.content)
              _this.parseRooms(data)
              _this.updateDetailsSearchParams(data)

              if(_this.$gtm){

                _this.$gtm.trackEvent({
                  event: 'customEvent',
                  category: 'property',
                  action: 'viewDetail',
                  label: data.content.name,
                  value: 0,
                });

                // TODO switch
                let eventGeoExtraValue = (_this.geo.matchLevel == 'inSalabam')
                                              ? _this.geo.inSalabamId
                                              : (_this.geo.matchLevel == 'property')
                                                  ? _this.geo.propertyId
                                                  : 0;

                _this.$gtm.trackEvent({
                  event: 'customEvent',
                  category: 'searchDetailView',
                  action: _this.geo.matchLevel,
                  label: _this.geo.label,
                  value: eventGeoExtraValue,
                });



                this.$gtm.trackEvent({
                  event: 'view_item',
                  ecommerce: {
                    currency: "EUR",
                    items: _this.parseGa4EcommerceItems(data, _this.site.brand),
                    transaction_id: "",
                    affiliation: "",
                    value: 0, // Sum of (price * quantity) for all items.
                    tax: 0,
                    shipping: 0,
                    coupon: "",
                  }
                });



              }

              if(window.LogRocket) window.LogRocket.track('propertyDetails')
              if(window.clarity) window.clarity("set", "property", "details")

          })
  },

      updateDetailsSearchParams(data){
        // salvo i parametri di ricerca che ritornano dal dettaglio
        // nelle conferme DEVONO essere mostrati questi, non quelli del form
        // (ogni tanto c'è qualche furbo che cerca una data, poi dal dettaglio cambia data
        // e clicca prenota senza fare la ricerca, la conferma mostra la nuova data ma in
        // realtà sta prenotando quella cercata prima)
        this.detailsSearchParams = {
          checkin: data.checkin,
          checkout: data.checkout,
          nights: data.nights,
          occupancy: data.occupancy
        }
      },

    // replicato in room
      toggleInternalModal(refModal, open, start)
      {
          let _this = this
          _this.firstSlideIndex = start
          if(open)
          {
              _this.$refs[refModal].show()
          }
          else
          {
              _this.$refs[refModal].hide()}
          },

      updateSearch()
      {
          let _this = this,
              scroll = window.pageYOffset,
              pageWrapper = document.getElementsByClassName('property-details')[0],
              pageWrapperHeight = pageWrapper.clientHeight,
              parameters = {
                  checkin: _this.checkin,
                  checkout: _this.checkout,
                  occupancy: _this.occupancy,
                  propertyId: parseInt(_this.propertyId),
              },
              newSearch = JSON.stringify(parameters)
              pageWrapper.style.minHeight = pageWrapperHeight+'px';

          // evita load more e append, altrimenti l'utente non capisce cosa sta succedendo
          // avvio la ricerca SOLO se i criteri sono cambiati
          if(newSearch == _this.oldSearch) {
            this.oldSearchMessage = true
            return
          }

          _this.oldSearch = newSearch
          _this.loadingRooms = true
          _this.$parent.getResults()
          this.oldSearchMessage = false

          Api
              .getDetails(parameters)
              .then((data) => {
                  //window.console.log('updateDetail')
                  _this.parseProperty(data.content)
                  _this.parseRooms(data)
                  _this.updateDetailsSearchParams(data)
                  window.scrollTo(0, scroll)
                  pageWrapper.style.minHeight = 0+'px';
              })
      },

      parseProperty(propertyData)
      {
          this.loadingProperty = true
          this.property.static = propertyData.static
          this.property.address = propertyData.address
          this.property.phone = propertyData.phone
          this.property.details = propertyData.details
          this.property.amenities = Object.values(propertyData.details.propertyAmenities)
          this.property.category = propertyData.category
          propertyData.images.forEach(image => {
              if (image.links['1000px'] !== undefined) image.src = image.links['1000px'].href
              else if (image.links['350px'] !== undefined) image.src = image.links['350px'].href
              else if (image.links['200px'] !== undefined) image.src = image.links['200px'].href
              else image.src = image.links['70px'].href
          })
          this.property.images = propertyData.images
          this.property.location = propertyData.location
          this.property.name = propertyData.name
          this.property.stars = {
              rating: propertyData.stars.rating,
              type: propertyData.stars.type,
              intval: Math.floor(parseFloat(propertyData.stars.rating)),
              floatval: parseFloat(propertyData.stars.rating)%1
          }
          this.property.statistics = propertyData.statistics

          // per funzionalità LITE
          this.property.lite = User.getLiteProperty(propertyData, 'detail')
          User.addToHistory({
              type: 'property',
              value: this.property.lite
          })

          this.loadingProperty = false
      },

      parseRooms(roomData)
      {
          //window.console.log('parseRooms')
          // caso in cui dalla pagina dettaglio ho cambiato date/pax e non c'è più disponibilità
          if(!roomData.availabilities.rooms)
          {
              //window.console.log('no rooms')
              this.rooms = []
              this.loadingRooms = false
              return
          }
          this.loadingRooms = true
          this.rooms = Object.values(roomData.availabilities.rooms)

          let totRates = 0;
          this.rooms.forEach(room => {
              room.images = []
              let contentRoom = roomData.content.rooms[room.id]

              if(!contentRoom){
                //window.console.log('missing room.id: ', room.id)
                return
              }

              if(contentRoom.images)
              {
                  contentRoom.images.forEach(image => {
                      let imageSrc = ''
                      if (image.links['1000px'] !== undefined) imageSrc = image.links['1000px'].href
                      else if (image.links['350px'] !== undefined) imageSrc = image.links['350px'].href
                      else if (image.links['200px'] !== undefined) imageSrc = image.links['200px'].href
                      else imageSrc = image.links['70px'].href
                      room.images.push({
                          src: imageSrc,
                          caption: image.caption
                      })
                  })
              }
              room.amenities = contentRoom.amenities
              room.bed_groups = contentRoom.bed_groups
              room.descriptions = contentRoom.descriptions
              room.name = contentRoom.name
              room.occupancy = contentRoom.occupancy

              totRates += room.rates.length
          })


          if(this.$gtm){

            this.$gtm.trackEvent({
              event: 'customEvent',
              category: 'statistics',
              action: 'detail_rooms',
              label: this.propertyId,
              value: this.rooms.length,
            })

            this.$gtm.trackEvent({
              event: 'customEvent',
              category: 'statistics',
              action: 'detail_rates',
              label: this.propertyId,
              value: totRates,
            })

          }


          this.loadingRooms = false
      },

      roomSelected(e) {
          let room = this.rooms.filter(function (el) {
              return el.id == e.roomId
          })[0]
          let rates = Object.keys(room.rates).map(key => {
              return room.rates[key];
          })
          let rate = rates.filter(function (el) {
              return el.id == e.rateId
          })[0]
          let bookingData = {}
          bookingData.hotelId = this.propertyId
          bookingData.productid = ''

          bookingData.nightlyRate = ''

          // detailsSearchParams
          bookingData.arrivalDate = this.detailsSearchParams.checkin
          bookingData.departureDate = this.detailsSearchParams.checkout
          bookingData.nights = this.detailsSearchParams.nights
          bookingData.numberOfAdults = this.detailsSearchParams.occupancy.adults
          bookingData.childs = this.detailsSearchParams.occupancy.children
          bookingData.numberOfChildren = this.detailsSearchParams.occupancy.children.length

          bookingData.roomDescription = room.name
          bookingData.roomDescriptionLong = (room.descriptions && room.descriptions.overview)? room.descriptions.overview : ''
          bookingData.bedTypeId = rate.bedding[e.bedKey].pricecheck_token
          bookingData.bedType = rate.bedding[e.bedKey].label
          bookingData.roomTypeCode = ''
          bookingData.roomAmenities = room.amenities
          bookingData.roomId = e.roomId
          bookingData.rateId = e.rateId
          bookingData.live_price = rate.live_price

          bookingData.payments = {
              'welfare': {
                  'amount': 0,
              },
              'cc': {
                  'amount': 0,
                  'nameSurname': '',
                  'paymentIntentId': ''
              },
              'etc': {
                  'amount': 0,
                  'vouchers': [],
                  /*
                  [
                      {
                      'value': 10,
                      'count': 1
                      },
                      {
                      'value': 20,
                      'count': 1
                      }
                  ]
                  */
              },
              'discountCodes': {
              'amount': 0,
              'codes': [],
              },
              'scalapay': {
              'amount': 0,
              'orderToken': '',
              }
          },

          bookingData.calculatedTotal = ''
          bookingData.surchargeTotal = ''
          bookingData.currencyCode = 'EUR'
          bookingData.smokingPreferences = 'NC'
          bookingData.txtSmokingPreferences = 'non fumatori'
          bookingData.hotelName = this.property.name
          bookingData.hotelCity = this.property.address.city
          bookingData.hotelPhone = this.property.phone
          bookingData.hotelAddress = this.property.address.line_1
          bookingData.hotelPostalCode = (this.property.address.postal_code)? this.property.address.postal_code : '',
          bookingData.hotelCountryCode = this.property.address.country_code
          bookingData.hotelDetails = this.property.details
          bookingData.thumbNailUrl = '' // TODO
          bookingData.rateCheck = '' // TODO
          bookingData.upselling_amount = 0 // TODO
          bookingData.listPos = 0 // TODO
          bookingData.searchLogId = 0 // TODO
          bookingData.refundable = rate.refundable
          bookingData.refundableBefore = rate.refundableBefore

          bookingData.ck_sortFilterFromPolygon = '' // TODO

          // TODO
          // bookingData.cacheKeysUsed = [Objct Object] // preview.cks

          bookingData.affiliateReferenceId = this.affiliateReferenceId
          bookingData.chargeableRate = rate.totals.inclusive
          bookingData.marketingFee = rate.totals.marketing_fee
          bookingData.totals = rate.totals
          bookingData.rateAmenities = rate.amenities
          bookingData.rateAmenitiesEps = rate.amenitiesEps

          this.mixinSendMutation('setBookingData', bookingData)
          this.$router.push({ name: 'checkout' })
      },

      showModalAmenities(){
        this.$root.$emit('bv::show::modal', 'modal-property-attributes')
      }
    }
  }
</script>


<style lang="scss">

.property-details
{

    #search-form
    {
        //width:70%;
        background-color:transparent;
    }

    .hide-multiple-spinner + .hide-multiple-spinner
    {
        display:none !important;
    }
    .property .btn-back
    {
        justify-content:flex-end;
    }
    &__top
    {
        display:flex;
        //justify-content: space-between;
        align-items: flex-start;
        margin-top:16px;
        box-shadow:$box-shadow;
        background:$white;
        padding:14px 18px;
        border-radius: $border-radius-lg;
        margin-bottom:$grid-gutter-width/2;
        &>div
        {
            width:100%;
            @include customize-bordered-element("secondary");
            padding-top:0;
            padding-bottom:0;
        }
        .d-flex
        {
            flex-wrap: wrap;
        }
        .lite__favorite,
        .lite__visited
        {
            margin-top: 15px;

            @include media-breakpoint-up(md){
                margin:0;
            }
        }
        .stars
        {
            flex:1;
            justify-content: flex-end;
        }
        .property-pad__rates
        {
            margin-left:0;
        }
        @include media-breakpoint-up(lg)
        {
            .d-flex
            {
                flex-wrap: nowrap;
                width:100%;
            }
        }
        @include media-breakpoint-up(xl)
        {
            padding:28px 36px;
            margin-top:26px;
        }
    }
    .property__heading
    {
        color:$black;
        font-size:20px;
        font-weight:600;
        margin-bottom:0;
        margin-right:1rem;
        width:auto;
        @include media-breakpoint-up(xl)
        {
            width:auto;
            font-size:25px;
        }
    }

    .property__highlight
    {
        margin-bottom:$grid-gutter-width/4;
        box-shadow:$box-shadow;
        background:$white;
        border-radius: $border-radius-lg;
        margin-bottom:$grid-gutter-width/2;
        overflow: hidden;
        .btn-chevron-right
        {
            justify-content: flex-end;
            margin-top: 10px;
        } 
        .highlight__right
        {
            padding:$grid-gutter-width/2;
            hr
            {
                margin-top:0.5rem;
                margin-bottom:0.5rem;
            }
            svg
            {
                //height:86px;
            }
        }
        @include media-breakpoint-up(lg)
        {
            display:flex;
            .highlight__left
            {
                width:50%;
            }
            .highlight__right
            {
                padding:$grid-gutter-width/2;
                width:50%;
                hr
                {
                    margin-bottom:1.5rem;
                }
            }
        }

        #slick-prev
        {
            //left:0;
        }

        #slick-next
        {
            right:12px;
        }

        .slick-slide,
        .slick-slide > div,
        .slick-slide > div > div
        {
            display:flex;
            flex:1;
            height:100%;
        }
    }

    .map-detail
    {
        cursor:pointer;
        svg
        {
            display:block;
        }
    }

    .property__amenities
    {
        display:flex;
        flex-wrap: wrap;
        .amenity
        {
            //font-size:17px;
            font-weight:500;
            width: auto;
            max-width: none;
            min-width: auto;
        }
    }

    .property-image
    {
        width:100%;
        //height:100%;
        min-height:220px;
        border-top-right-radius: $border-radius-lg;
        border-bottom-right-radius: $border-radius-lg;
        overflow:hidden;


        position:relative;
        border-radius: $border-radius-lg;
        &:before
        {
            content:"";
            @include customize-background-color("primary");
            @include customize-gradient(vertical, important);
            position:absolute;
            top:0;
            right:0;
            height:100%;
            width: 100%;
            border-top-right-radius: $border-radius-lg;
            border-bottom-right-radius: $border-radius-lg;
            z-index: 1;
        }
        .property-image-bg
        {
            display:block;
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background-size:cover;
            background-position:center center;
            z-index: 2;
            width:calc(100% - 15px);
            transition:$transition-fade;
            z-index: 2;
            border-top-right-radius: $border-radius-lg;
            border-bottom-right-radius: $border-radius-lg;
            margin-right:15px;
        }


        @include media-breakpoint-up(md)
        {
            min-height:320px;
        }
        @include media-breakpoint-up(lg)
        {
            min-height:440px;
        }
    }

    .property__address
    {
        margin-top:1rem;
        @include customize-color("cta");
        font-size:12px;
        font-weight:400;
        display:flex;
        justify-content: flex-start;
        align-items: center;
        svg
        {
            margin-right:8px;
            path
            {
                @include customize-fill("cta");
            }
        }
        @include media-breakpoint-up(md)
        {
            font-size:15px;
        }
    }

    .property-pad__description
    {
        font-size:15px;
        color:$smooth-black;
        font-weight:400;
        margin-bottom:0;
        i
        {
            cursor:pointer;
            @include customize-color("secondary");
            font-weight:500;
        }
    }

    .detail-first-title
    {
        margin-top:1rem;
        //font-size: $font-size-sm;
        @include customize-color("secondary");
    }
    .detail-first-content
    {
        //font-size: $font-size-sm;
    }

    .rooms__title
    {
        display:block;
        padding:$grid-gutter-width/2;
        color:$black;
        font-weight:600;
        font-size: 20px;
        margin-bottom:0;
        @include media-breakpoint-up(xl)
        {
            //padding:$grid-gutter-width/2;
            font-size: 25px;
        }
    }

    .info__panel
    {
        padding:$grid-gutter-width/2;
        border-radius:$border-radius-lg;
        box-shadow:$box-shadow;
        margin-bottom:$grid-gutter-width/2;
        background:$white;
        .info__panel-title
        {
            /*
            font-weight:600;
            font-size:25px;
            color:$black;
            margin-bottom:1rem;
            */

            //riprende .reservation-resume strong da reservations nella vista checkout/conferma
            color:$black;
            margin-bottom:1.25rem;
            font-size: 19px;
            font-weight:600;
        }
        .info__panel-content
        {
            /*
            color:$smooth-black;
            line-height:160%;
            */
        }
    }

    .stars
    {
        transform:none;
        margin-left:0.5rem;
        span
        {
            width:18px;
            margin-right:2px;
        }
    }

    .search
    {
        margin-bottom:$grid-gutter-width/2;
        .search-form-fields
        {
            padding-right:0 !important;
            max-width: 100%;
        }
        .search-form-autocomplete
        {
            display:none !important;
        }
        .search-form-submit
        {
            min-width:20%;
        }
    }

    .slick-slide
    {
        cursor:pointer;
    }

    @include media-breakpoint-down(md)
    {
        .property-pad__rates
        {
            margin-top:0;
        }
        .stars
        {
            margin-left:0;
            span
            {
                width:14px;
            }
        }
    }
}

div[data-class=internalGallery]
{
    .modal-dialog
    {
        max-width: calc(100% - 16px);
    }
    .closeInternal
    {
        position:fixed;
        top:0;
        right:0;
        color:$white;
        padding:1rem;
        font-size: 24px;
        cursor:pointer;
        z-index: 99999;
    }
    .modal.show
    {
        display:flex !important;
    }
    .modal-dialog
    {
        flex:1;
        margin-top:1rem;
        margin-bottom:1rem;
    }
    .modal-content
    {
        //border-radius:$border-radius;
        //overflow:hidden;
        background-color:transparent;
    }
    .modal-content,
    .slick-container,
    .slick-slider,
    .slick-list,
    .slick-track,
    .slick-slide,
    .slick-slide div
    {
        height:100%;
    }
    .slick-slide
    {
        position:relative;
        //height:100px;
        //overflow:hidden;
        //float:none;
    }
    .internalImage
    {
        display:flex !important;
        justify-content: center;
        align-items:center;
        align-content:center;
        padding-top:2rem;
        padding-bottom:2rem;
        img
        {
            width:auto;
            margin:0 auto;
            max-width: 100%;
        }
    }
    .slick-caption
    {
        height: auto !important;
        background:transparent;
        position:absolute;
        top:0;
        width:100%;
        left:0;
        right:0;
        font-size: 12px;
        color:$white;
        span
        {
        }
        text-align:center;
        @include media-breakpoint-up(md)
        {
            font-size:18px;
        }
    }
    .slick-dots
    {
        //position:relative;
        bottom:0;
    }
}

.modal-content
{
    .property__amenities
    {
        display:flex;
        flex-wrap:wrap;

        .amenity {
            font-size: 13px;
            font-weight: 500;
            line-height: normal;
            padding: 6px 20px 6px 6px;
            margin: 6px;
            width: 30%;
        }
    }
    .info__panel-title
    {
        font-weight:600;
        font-size:25px;
        color:$black;
        margin-bottom:1rem;
    }
    .info__panel-content
    {
        color:$smooth-black;
        font-size: 15px;
        font-weight:400;
        line-height:160%;
    }
}
</style>