<template>
    <div class="property-details-modal">

      <div v-if="details.location">
        <h6 class="property-detail-title">Location</h6>
        <p class="property-detail-content">{{details.location}}</p>
        <br />
      </div>

      <div v-if="details.amenities">
        <h6 class="property-detail-title">Servizi</h6>
        <p class="property-detail-content">{{details.amenities}}</p>
        <div v-if="details.attributes.general">
          <ul v-for="(value,index) in details.attributes.general" v-bind:key="'property-info-'+index">
            <li v-html="value.name" class="property-detail-content"></li>
          </ul>
        </div>
        <br />
      </div>

      <!--<div v-if="details.attributes.pets">
        <h6 class="property-detail-title">Policy animali</h6>
        <ul v-for="(value,index) in details.attributes.pets" v-bind:key="'property-info-'+index">
          <li v-html="value.name" class="property-detail-content"></li>
        </ul>
        <br />
      </div>-->

      <div v-if="details.rooms">
        <h6 class="property-detail-title">Descrizione camere</h6>
        <p class="property-detail-content">{{details.rooms}}</p>
        <br />
      </div>

      <div v-if="details.attractions">
        <h6 class="property-detail-title">Attrazioni nelle vicinanze</h6>
        <span class="property-detail-content" v-html="details.attractions"></span>
        <br />
      </div>

      <div v-if="details.fees_optional">
        <h6 class="property-detail-title">Servizi facoltativi</h6>
        <span class="property-detail-content" v-html="details.fees_optional"></span>
      </div>

    </div>
</template>

<script>
export default {
    name: 'property-info',
    props:
    {
        details: Object,
    }
}
</script>

<style lang="scss">
    // see app.scss
</style>