<template>
    <div :class="'rate '+mixinGetIntegrationInfo(rate.live_price).class">
        <div class="rate__left">

            <h5 class="rate__title">Soluzione {{rateIndex+1}}</h5>
            
            <hr>

            <span class="amenity fail" v-if="rate.refundable != true" v-b-popover.hover.top="'La prenotazione non potrà essere cancellata nè modificata. Prenota quando sei sicuro del tuo viaggio'">
                <div>
                    <SvgInfoCircle/>
                </div>
                Soluzione non rimborsabile
            </span>
            <span v-else class="amenity icon-refundable" v-b-popover.hover.top="'Potrai cancellare la prenotazione entro il ' + rate.refundableBefore + '. Se dovessi farlo riceverai per email le istruzioni per fare una nuova prenotazione entro 12 mesi.'"> Cancellazione gratuita</span>

            <hr>

            <div v-if="Object.values(rate.amenities).length">
                <div class="property__amenities">
                   <span v-for="(amenity) in Object.values(rate.amenitiesEps).reverse().slice(0,2)" v-bind:key="amenity.id" :class="'amenity ' + (icons['propertyAmenities'][amenity.amenity] !== undefined ? icons['propertyAmenities'][amenity.amenity] : 'icon-default')">
                      {{amenity.name}}
                   </span>

                   <span v-if="Object.values(rate.amenitiesEps).length > 2" @click="$bvModal.show('modal-room'+roomId+'-rate-'+rate.id+'-services')">
                      <br/><BtnChevronRight :text="'Tutti i servizi'" />
                   </span>
                </div>

                <b-modal :id="'modal-room'+roomId+'-rate-'+rate.id+'-services'" size="lg" hide-footer>
                    <template v-slot:modal-title>
                        Servizi inclusi nella tariffa
                    </template>
                    <div class="modal-section">
                        <div class="property__amenities">
                           <span v-for="(amenity) in Object.values(rate.amenitiesEps).reverse()" v-bind:key="amenity.id" :class="'amenity ' + (icons['propertyAmenities'][amenity.amenity] !== undefined ? icons['propertyAmenities'][amenity.amenity] : 'icon-default')">
                                {{amenity.name}}
                            </span>
                        </div>
                    </div>
                </b-modal>

                <hr>
            </div>

            <!-- SCELTA LETTO: tooltip richiesta non confermata condizionale solo se ci sono più scelte -->
            <div v-if="Object.keys(this.rate.bedding).length == 1">
                <b-form-group>
                    <b-form-radio v-for="(bed,index) in rate.bedding" v-bind:key="index" v-model="bedKey" :name="rate.id+'-bedding-radios'" :value="index">{{bed.label}}</b-form-radio>
                </b-form-group>
            </div>
            <div v-else v-b-popover.hover.top="'Richiesta non confermata soggetta a disponibilità'">
                <b-form-group>
                    <b-form-radio v-for="(bed,index) in rate.bedding" v-bind:key="index" v-model="bedKey" :name="rate.id+'-bedding-radios'" :value="index">{{bed.label}}</b-form-radio>
                </b-form-group>
            </div>
            <!-- scelta letto -->


        </div>

        <div class="rate__right">

            <!--@click spostato da .room__prices a <PropertyPrices /> per gestire il disclaimer modal non refundable-->
            <div
                class="room__prices"
                @click="/*mixinGetIntegrationInfo(rate.live_price).canCheckout ? ($parent.$emit('room-selected',{rateId: rate.id, roomId: roomId, bedKey: bedKey})) : {}*/"
            >
                <PropertyPrices
                    v-on:room-selected="mixinGetIntegrationInfo(rate.live_price).canCheckout ? ($parent.$emit('room-selected',{rateId: rate.id, roomId: roomId, bedKey: bedKey})) : {}"
                    :refundable="rate.refundable"
                    :additionalInfo="{}"
                    :uniqueId="roomId+rateIndex"
                    :disableUnavailable="true"
                    :live_price="rate.live_price"
                    :btnText="'Prenota'"
                    :instantiatedOnRoute="$route.name"
                />
            </div>

            <div v-if="fees" :id="'popover-target-fees-'+roomId+'-'+rateIndex">
                <div class="fees">
                    <span><SvgInfoCircle/> Da pagare in struttura:</span>
                    <span class="price">{{fees*100/100}}€</span>

                    <b-popover :target="'popover-target-fees-'+roomId+'-'+rateIndex" triggers="hover" placement="top">
                        <!--<template v-slot:title>Costi non inclusi nella tariffa e da pagare in struttura:</template>-->
                        <span>Costi non inclusi nella tariffa e da pagare in struttura:</span><br/>
                        <div class="d-flex justify-content-between" v-if="rate.totals.mandatory_tax > 0">
                            <span class="pr-2">Tassa di soggiorno:</span>
                            <span>{{rate.totals.mandatory_tax*100/100}}€</span>
                        </div>
                        <div class="d-flex justify-content-between" v-if="rate.totals.mandatory_fee > 0">
                            <span class="pr-2">Spese di pulizia ed utenze:</span>
                            <span>{{rate.totals.mandatory_fee*100/100}}€</span>
                        </div>
                        <div class="d-flex justify-content-between" v-if="rate.totals.resort_fee > 0">
                            <span class="pr-2">Resort fee:</span>
                            <span>{{rate.totals.resort_fee*100/100}}€</span>
                        </div>
                    </b-popover>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import PropertyPrices from '../blocks/property/property-prices'
import BtnChevronRight from '../atoms/btn-chevron-right'
import SvgInfoCircle from './../svg/info-circle'
export default {
    name: 'rate',
    components:
    {
        BtnChevronRight,
        SvgInfoCircle,
        PropertyPrices
    },
    props:
    {
        rate: Object,
        rateIndex: Number,
        roomId: String
    },
    computed: {
        availability: {
            get() { return this.$store.state.integration.availability },
            set() {}
        },
    },
    data() {
        return {
            amenities: {},
            icons:              Vue.prototype.$config.icons,
            amenitiesLabels:    Vue.prototype.$config.amenities,
            bedKey:             Object.keys(this.rate.bedding)[0],
            fees:               this.rate.totals.mandatory_tax + this.rate.totals.mandatory_fee + this.rate.totals.resort_fee,
        }
    },
    methods:
    {
        stringToDate(string)
        {
            return string.split(' ')[0]
        }
    },
    beforeMount(){
        this.amenities = Vue.prototype.$config.amenities
    }
}
</script>



<style lang="scss">
    
    
    

    .rate
    {
        display:flex;
        margin-bottom:$grid-gutter-width/2;
        &:not(:last-child)
        {
            border-bottom:1px solid $gray-300;
            padding-bottom:$grid-gutter-width/2;
        }
        .rate__left
        {
            width: 50%;
            @include media-breakpoint-up(xxl)
            {
                width: 60%;
            }

            .custom-control {
                margin-bottom: 10px;
            }

            .custom-control-label
            {
                color:$black;
                padding: 0;
                font-size: 15px;
            }
            
        }
        .rate__right
        {
            width: 50%;
            padding-left: $grid-gutter-width/3;

            @include media-breakpoint-up(xxl)
            {
                width: 40%;
            }
        }
        .rate__title
        {
            color:$black;
            font-size:22px;
            font-weight:600;
        }
        .fail
        {
            font-size: 13px;
            line-height: normal;
        }
        .property__amenities
        {
            .amenity
            {
                width:50%;
            }
        }
        .fees
        {
            color:$black;
            display:flex;
            justify-content: space-between;
            font-size: 12px;
            line-height: 120%;
            max-width: 80%;
            margin:auto;
            align-items: center;
            cursor:pointer;
            .price
            {
                display:flex;
                align-items: center;
                font-weight: 500;
            }
            svg
            {
                min-width:12px;
                width:12px;
                margin-top:-1px;
                margin-right:6px;
            }
            span
            {
                display:flex;
                align-items:center;
            }
        }
    }
</style>