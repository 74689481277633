<template>
    <div class="room">
        <b-row>
            <b-col sm="12" lg="6">
                <div class="room__left">
                    <h5 class="room__name">{{room.name}}</h5>

                    <div class="room__occupancy" v-if="room.occupancy && room.occupancy.max_allowed && room.occupancy.max_allowed.total">
                        <div>
                            <svg width="21" height="14" viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5999 7.19995C16.091 7.19995 17.2999 5.99117 17.2999 4.5C17.2999 3.00883 16.091 1.80005 14.5999 1.80005C13.1087 1.80005 11.8999 3.00883 11.8999 4.5C11.8999 5.99117 13.1087 7.19995 14.5999 7.19995Z" fill="#333333"/>
                            <path d="M20.6 12.6C20.4 9.99998 18.8 8 14.6 8C14 8 13.5 7.99998 13 8.09998C14.2 9.09998 15 10.6 15.1 12.4V12.5C15.1 12.7 15.1 12.8 15 13H20.1C20.4 13.1 20.6 12.9 20.6 12.6Z" fill="#333333"/>
                            <path d="M7.49963 6.5C9.10126 6.5 10.3997 5.2016 10.3997 3.59998C10.3997 1.99835 9.10126 0.699951 7.49963 0.699951C5.89801 0.699951 4.59961 1.99835 4.59961 3.59998C4.59961 5.2016 5.89801 6.5 7.49963 6.5Z" fill="#333333"/>
                            <path d="M0.899902 12.5C0.899902 12.8 1.0999 13 1.3999 13H13.5999C13.8999 13 14.0999 12.8 14.0999 12.5C13.8999 9.7 12.0999 7.5 7.49988 7.5C2.89988 7.5 1.0999 9.7 0.899902 12.5Z" fill="#333333"/>
                            </svg>
                            Per {{room.occupancy.max_allowed.total}} <span v-if="room.occupancy.max_allowed.total == 1">persona</span><span v-else>persone</span>
                        </div>

                        <!-- TODO questa sezione è errata, mostra tutte le scelte letto come se fossero quelli presenti -->
                        <div v-if="false" >
                            <div v-for="(bed,index) in room.bed_groups" v-bind:key="index">
                                <span>
                                    <svg width="21" height="13" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.1999 4.30005C18.8999 4.30005 18.6999 4.50005 18.6999 4.80005V7.5H2.79987V1C2.79987 0.7 2.59987 0.5 2.29987 0.5H1.3999C1.0999 0.5 0.899902 0.7 0.899902 1V12C0.899902 12.3 1.0999 12.5 1.3999 12.5H2.29987C2.59987 12.5 2.79987 12.3 2.79987 12V10.6H18.6999V12C18.6999 12.3 18.8999 12.5 19.1999 12.5H20.0999C20.3999 12.5 20.5999 12.3 20.5999 12V4.80005C20.5999 4.50005 20.3999 4.30005 20.0999 4.30005H19.1999Z" fill="#333333"/>
                                    <path d="M5.29987 6.19995C6.34921 6.19995 7.19989 5.34939 7.19989 4.30005C7.19989 3.25071 6.34921 2.40002 5.29987 2.40002C4.25052 2.40002 3.3999 3.25071 3.3999 4.30005C3.3999 5.34939 4.25052 6.19995 5.29987 6.19995Z" fill="#333333"/>
                                    <path d="M9.8999 2.4C9.3999 2.3 8.89988 2.5 8.49988 2.9C8.09988 3.2 7.8999 3.70002 7.8999 4.30002V6.30002H18.0999C17.3999 4.70002 14.4999 2.9 9.8999 2.4Z" fill="#333333"/>
                                    </svg>
                                    {{bed.description}}
                                </span>
                            </div>
                        </div>

                        <!--<br/>-->

                        <span class="room__modal-link" @click="$bvModal.show('modal-room-'+room.id+'-features')">
                            <BtnChevronRight :text="'Caratteristiche della camera'" />
                        </span>

                        <b-modal :id="'modal-room-'+room.id+'-features'" size="lg" hide-footer>
                            <template v-slot:modal-title>
                                Caratteristiche della camera
                            </template>
                            <div class="modal-section">
                                <p v-if="room.descriptions" v-html="room.descriptions.overview"></p>
                                <div class="property__amenities">
                                    <span v-for="(at,index) in room.amenities" v-bind:key="index" :class="'amenity '+(icons.amenities[at.id] !== undefined ? icons.amenities[at.id]  : 'icon-default')">
                                        {{at.name}}
                                    </span>
                                </div>
                            </div>
                        </b-modal>
                    </div>

                    <div class="slick-container">
                        <Slick :ref="'roomSlider-'+room.id" :id="'roomSlider-'+room.id" :options="slickOptions">
                            <div v-for="(thumb,thumbIndex) in (room.images).slice(0,100)" v-bind:key="thumbIndex">
                                <div class="room__image" @click="toggleInternalModal('roomGalleryFullwidth-'+room.id,true,thumbIndex)">
                                    <div class="room__image-bg" :style="'background-image:url('+thumb.src+')'"></div>
                                    <span class="slick-caption">{{thumb.caption}}</span>
                                </div>
                            </div>
                        </Slick>
                        <span variant="light" class="slick-arrow slick-prev" :id="'slick-prev-'+room.id"><SvgChevronLeft /></span>
                        <span variant="light" class="slick-arrow slick-next" :id="'slick-next-'+room.id"><SvgChevronRight /></span>
                    </div>

                    <b-modal :id="'roomGalleryFullwidth-'+room.id" data-class="internalGallery" :ref="'roomGalleryFullwidth-'+room.id" size="xl" hide-header hide-footer>
                        <div class="d-block text-center slick-container">
                            <Slick
                                :options="{
                                    dots:true,
                                    fade:true,
                                    prevArrow: '#slick-prev-internal-'+room.id,
                                    nextArrow: '#slick-next-internal-'+room.id,
                                    asNavFor: '#roomSlider-'+room.id,
                                    initialSlide: firstSlideIndex
                                }"
                            >
                                <div v-for="(thumb,thumbModalIndex) in (room.images).slice(0,100)" v-bind:key="thumbModalIndex" class="internalImage">
                                    <div class="slick-caption"><span>{{thumb.caption}}</span></div>
                                    <img :src="thumb.src">
                                </div>
                            </slick>
                            <span variant="light" class="slick-arrow slick-prev" :id="'slick-prev-internal-'+room.id"><SvgChevronLeft /></span>
                            <span variant="light" class="slick-arrow slick-next" :id="'slick-next-internal-'+room.id"><SvgChevronRight /></span>
                        </div>
                        <div class="closeInternal" @click="toggleInternalModal('roomGalleryFullwidth-'+room.id,false,thumbModalIndex)">
                            <SvgCross />
                        </div>
                    </b-modal>

                    
                    <div
                        class="room__bedding-alert"
                        v-if="(occupancy.adults + occupancy.children.length) > Object.values(room.rates[0].bedding)[0].paxCount"
                    >
                        <div @click="$bvModal.show('modal-room-'+room.id+'-features')">
                            <b-alert variant="warning" show class="bedding-alert">
                                <SvgInfoCircle v-b-popover.hover.top="'Il numero di posti letto di questa camera potrebbe essere inferiore al numero di viaggiatori e potrebbe essere richiesto un supplemento'"/>
                                Controlla il numero dei letti disponibili
                            </b-alert>
                        </div>

                        <div @click="$bvModal.show('modal-room-'+room.id+'-details')">
                            <b-alert variant="warning" show class="bedding-alert">
                                <SvgInfoCircle v-b-popover.hover.top="'La struttura potrebbe richiedere un supplemento per eventuali letti aggiuntivi necessari. Contattaci ad '+site.emailSupport+' o scegli un\'altra camera'"/>
                                Verifica il costo per eventuali letti aggiuntivi
                            </b-alert>
                        </div>

                        <b-modal :id="'modal-room-'+room.id+'-details'" size="lg" hide-footer>
                            <template v-slot:modal-title>
                                Informazioni importanti
                            </template>
                            <div class="modal-section">
                                <PropertyImportantInfo v-if="propertyDetails.specialDetails" :specialDetails="propertyDetails.specialDetails" />
                                <br>
                                <PropertyToKnow :details="propertyDetails" />
                            </div>
                        </b-modal>

                    </div>

                </div>
            </b-col>
            <b-col sm="12" lg="6">
                <div class="room__right">
                    <RoomRate v-for="(rate,index) in room.rates" v-bind:key="index" :rate="rate" :rateIndex="index" :roomId="room.id" />
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Vue from 'vue'
import BtnChevronRight from '../atoms/btn-chevron-right'
import SvgInfoCircle from './../svg/info-circle'
import RoomRate from '../blocks/room-rate'
import PropertyToKnow from '../blocks/property/property-toKnow'
import PropertyImportantInfo from '../blocks/property/property-important-info'
import Slick from 'vue-slick'
import SvgChevronRight from './../svg/chevron-right'
import SvgChevronLeft from './../svg/chevron-left'
import SvgCross from './../svg/cross'

export default {
    name: 'room',
    components:
    {
        BtnChevronRight, SvgInfoCircle,
        RoomRate,
        Slick,
        PropertyToKnow,
        PropertyImportantInfo,
        SvgCross,
        SvgChevronLeft,
        SvgChevronRight,
    },
    props: {
        room: Object,
        propertyDetails: Object
    },
    computed:
    {
        occupancy: {
            get() { return this.$store.state.occupancy },
            set(value) { this.mixinSendMutation('setOccupancy',value) }
        }
    },
    mounted()
    {
        //window.console.log(this.room)
    },
    data(){
        return {
            thumbModalIndex: 0,
            firstSlideIndex: 0,
            site:               Vue.prototype.$config.site,   
            icons:              Vue.prototype.$config.icons,
            amenitiesLabels:    Vue.prototype.$config.amenities,
            slickOptions: {
                    //vertical: true,
                    //verticalSwiping: true,
                    //slidesToShow: 1,
                    //slidesToScroll: 1,
                    //infinite: false,
                    dots:true,
                    fade:true,
                    prevArrow: '#slick-prev-'+this.room.id,
                    nextArrow: '#slick-next-'+this.room.id,
                    /*
                    adaptiveHeight:true,
                    responsive: [{
                        breakpoint: 993,
                        settings: {
                            adaptiveHeight:false,
                            centerMode: true,
                            //slidesToShow: 3,
                            variableWidth: true,
                            vertical: false,
                            verticalSwiping: false,
                        }
                    }]*/
                },          
        }
    },
    methods:
    {
        // replicato in search-property
        toggleInternalModal(refModal, open, start)
        {
            let _this = this
            _this.firstSlideIndex = start
            if(open)
            {
                _this.$refs[refModal].show()
            }
            else
            {
                _this.$refs[refModal].hide()
            }
        },
    }
}
</script>

<style lang="scss">
    
    
    
    
    .room
    {
        box-shadow:$box-shadow;
        border-radius:$border-radius-lg;
        background:$white;
        overflow: hidden;
        margin-bottom:$grid-gutter-width/2;

        .room__bedding-alert
        {
            cursor:pointer;
            margin-top:$grid-gutter-width/2;
            margin-bottom:$grid-gutter-width/2;
            .bedding-alert
            {
                font-size: 14px;
            }
            svg
            {
                width:16px;
                opacity:0.75;
                margin-top:-3px;
                margin-right:4px;
            }
        }

        .room__left
        {
            padding:$grid-gutter-width/2;
            padding-right:0;
            @include media-breakpoint-up(xl)
            {
                padding:$grid-gutter-width/2;
            }
        }
        .room__right
        {
            padding:$grid-gutter-width/2;
            @include media-breakpoint-up(xl)
            {
                //padding:$grid-gutter-width/2;
            }
        }

        .room__name
        {
            color:$black;
            font-weight:600;
            font-size:20px;
            margin-bottom:1rem;
            @include media-breakpoint-up(xl)
            {
                font-size: 25px;            
            }
        }
        .room__occupancy
        {
            display:flex;
            flex-direction:column;
            font-size:15px;
            margin-bottom:2rem;
            svg
            {
                margin-right:8px;
            }
        }

        .room__modal-link
        {
            margin-top:15px;
        }

        .room__image
        {
            width:100%;
            //height:100%;
            min-height:260px;


            border-top-right-radius: $border-radius-lg;
            border-bottom-right-radius: $border-radius-lg;
            overflow:hidden;

            position:relative;
            border-radius: $border-radius-lg;
            &:before
            {
                content:"";
                @include customize-background-color("primary");
                @include customize-gradient('vertical', important);
                position:absolute;
                top:0;
                right:0;
                height:100%;
                width: 100%;
                border-top-right-radius: $border-radius-lg;
                border-bottom-right-radius: $border-radius-lg;
                z-index: 1;
            }
            .room__image-bg
            {
                display:block;
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                background-size:cover;
                background-position:center center;
                z-index: 2;
                width:calc(100% - 10px);
                transition:$transition-fade;
                z-index: 2;
                border-top-right-radius: $border-radius-lg;
                border-bottom-right-radius: $border-radius-lg;
                margin-right:10px;
            }



            @include media-breakpoint-up(md)
            {
                min-height:340px;
            }
        }

        .slick-prev
        {
            //left:0;
        }

        .slick-next
        {
            right:12px !important;
        }

        @include media-breakpoint-up(lg)
        {
            .room__right
            {
                border-left:$dashed-border;
            }
        }

        @include media-breakpoint-down(lg)
        {
            .room__right .rate
            {
                flex-wrap: wrap;
                button
                {
                    margin-bottom:1rem;
                }
                .rate__left,
                .rate__right
                {
                    width:100%;
                }

                .rate__right
                {
                    margin-top:1rem;
                    padding-left:0;
                }
            }
        }

        @include media-breakpoint-down(md)
        {
            .room__left
            {
                padding-left:0;
                padding-top:20px;
                padding-bottom:0;
            }
            .room__right
            {
                padding:10px 20px 20px 20px;
            }
            .room__bedding-alert
            {
                padding:0 20px;
                margin:20px 0 0 0;
            }
            .room__occupancy
            {
                padding-top:0;
                br
                {
                    display: none;
                }
            }

            .room__name
            {
                font-size:18px;
            }

            .room__modal-link
            {
                margin-top:6px;
                .btn-chevron-right
                {
                    font-size:13px;
                    svg
                    {
                        width:6 px;
                    }
                }
            }

            .room__name,
            .room__occupancy
            {
                padding-left:20px;
                padding-right:20px;
                margin-bottom:20px;
            }
        }
    }
</style>